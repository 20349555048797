<template>
  <v-row>
    <v-col v-if="impound.request.vehicle" cols="12">
      <v-table class="text-center">
        <thead>
          <tr>
            <th class="text-center">Make</th>
            <th class="text-center">Model</th>
            <th class="text-center">Year</th>
            <th class="text-center">Color</th>
            <th class="text-center">Chassis number</th>
            <th class="text-center">License Plate</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ impound.request.vehicle.make || '-' }}</td>
            <td>{{ impound.request.vehicle.model || '-' }}</td>
            <td>{{ impound.request.vehicle.year || '-' }}</td>
            <td>{{ impound.request.vehicle.color || '-' }}</td>
            <td>{{ impound.request.vehicle.chassis_number || '-' }}</td>
            <td>{{ impound.request.vehicle.license_plate || '-' }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-bind="vehicleAttrs"
        :error-messages="errors?.vehicle_id"
        label="Vehicle"
        clearable
        v-on="vehicleListeners"
      />
    </v-col>

    <v-col v-if="impound.request.owner" cols="12">
      <v-table class="text-center">
        <thead>
          <tr>
            <th class="text-center">Name</th>
            <th class="text-center">Phone</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ impound.request.owner.name || '-' }}</td>
            <td>{{ impound.request.owner.phone || '-' }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-bind="ownerAttrs"
        :error-messages="errors?.owner_id"
        label="Owner"
        clearable
        v-on="ownerListeners"
      />
    </v-col>

    <v-col
      v-if="impound.request.driver && !impound.request.owner_is_driver"
      cols="12"
    >
      <v-table class="text-center">
        <thead>
          <tr>
            <th class="text-center">Name</th>
            <th class="text-center">Phone</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ impound.request.driver.name || '-' }}</td>
            <td>{{ impound.request.driver.phone || '-' }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-col>

    <v-col v-if="impound.request.owner_is_driver" cols="12" class="text-center">
      The client declare that the driver is the owner
    </v-col>

    <v-col cols="12">
      <v-checkbox
        v-model="model.owner_is_driver"
        label="The driver is the owner"
      />
    </v-col>

    <v-col v-if="!model.owner_is_driver" cols="12">
      <v-autocomplete
        v-bind="driverAttrs"
        :error-messages="errors?.driver_id"
        label="Driver"
        clearable
        v-on="driverListeners"
      />
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-bind="streetsAttrs"
        aria-autocomplete="none"
        autocomplete="off"
        :error-messages="errors?.pickup_street_id"
        label="Pickup address"
        clearable
        v-on="streetsListeners"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="model.pickup_house_number"
        :error-messages="errors?.pickup_house_number"
        label="House number"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { stepProps } from '@/composables/task/stepOptions.js';
import { impound } from '@/store/storage/impound.js';
import { usePersonAutocomplete } from '@/composables/autocomplete/person-autocomplete.js';
import { useVehicleAutocomplete } from '@/composables/autocomplete/vehicle-autocomplete.js';
import { useStreetAutocomplete } from '@/composables/autocomplete/street-autocomplete.js';
import { watch } from 'vue';

const model = defineModel({ required: true, type: Object });

const { attrs: vehicleAttrs, listeners: vehicleListeners } =
  useVehicleAutocomplete({ minSearchLength: 1 });

watch(
  () => vehicleAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.vehicle_id = value;
    }
  }
);

const { attrs: driverAttrs, listeners: driverListeners } =
  usePersonAutocomplete({ minSearchLength: 1 });

watch(
  () => driverAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.driver_id = value;
    }
  }
);

const { attrs: streetsAttrs, listeners: streetsListeners } =
  useStreetAutocomplete({ minSearchLength: 1 });

watch(
  () => streetsAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.pickup_street_id = value;
    }
  }
);

const { attrs: ownerAttrs, listeners: ownerListeners } = usePersonAutocomplete({
  minSearchLength: 1,
});

watch(
  () => ownerAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.owner_id = value;
    }
  }
);

defineProps({
  ...stepProps,
});
</script>
