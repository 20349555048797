<template>
  <v-card>
    <BaseModal v-model="managePermissionsDialog" size="lg" height="80vh">
      <PermissionsForm :user-id="userId" />
    </BaseModal>

    <BaseModal v-model="changePasswordDialog" title="Change password">
      <v-card-text>
        <ChangePasswordForm
          v-model:model-loading="loadingChangePassword"
          :user-id="userId"
          @updated="changePasswordDialog = false"
        />
      </v-card-text>
    </BaseModal>

    <VDataTableServer
      v-bind="tableAttrs"
      height="70vh"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title class="d-print-none">
          <v-row>
            <v-col cols="12" md="6">users</v-col>
            <v-col cols="12" md="6" class="text-right">
              <BaseModal
                v-model="userFormDialog"
                :loading="loadingUserForm"
                title="User form"
              >
                <template #activator="{ props }">
                  <v-btn
                    color="success"
                    v-bind="props"
                    size="small"
                    text="Add user"
                  />
                </template>

                <v-card-text>
                  <UserForm
                    v-model:update="loadingUserForm"
                    @created="(userFormDialog = false), loadUsers()"
                  />
                </v-card-text>
              </BaseModal>

              <v-btn type="submit" form="userFilter" size="small" color="blue">
                Search
              </v-btn>
            </v-col>
          </v-row>

          <v-form id="userFilter" @submit.prevent="fetchItems">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="search.username" label="Username" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-title>
      </template>

      <template #[`item.username`]="{ item }">
        <a class="text-reset" :href="'users/' + item.username">
          <div>{{ item.username }}</div>
          <div>{{ item.email }}</div>
        </a>
      </template>

      <template #[`item.name`]="{ item }">
        <PeopleLink v-if="item.person" bold :person="item.person" />
      </template>

      <template #[`item.last_login`]="{ item }">
        {{ formatDate(item.last_login) || 'Never' }}
      </template>

      <template #[`item.weak_password`]="{ item }">
        <v-icon
          v-if="item.weak_password === true"
          color="error"
          icon="mdi-lock-alert"
        />
        <v-icon
          v-else-if="item.weak_password === null"
          color="grey"
          icon="mdi-lock-question"
        />
        <v-icon v-else color="success" icon="mdi-lock-check" />
      </template>

      <template #[`item.verified`]="{ item }">
        <v-icon v-if="item.verified" color="success" icon="mdi-check-bold" />
        <v-icon v-else color="error" icon="mdi-close-thick" />
      </template>

      <template #[`item.roles`]="{ item }">
        <div v-for="role in item.roles" :key="item.id + '-' + role.id">
          {{ role.name }}
        </div>
      </template>

      <template #[`item.permissions`]="{ item }">
        {{ item.permissions.length }}
      </template>

      <template #[`item.departments`]="{ item }">
        <div v-if="checkCanManageAllDepartments(item)">All</div>
        <div
          v-for="department in item.departments"
          v-else
          :key="department.id"
          class="text-nowrap"
        >
          <span class="font-weight-bold d-block">
            {{ stringLength(department.company.handle_name, 20) }}
          </span>
          <span class="d-block"> [{{ stringLength(department.name) }}] </span>
        </div>
      </template>

      <template #[`item.cases`]="{ item }">
        {{ item.debts.length }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props" variant="text" />
          </template>

          <v-list>
            <v-list-item>
              <v-btn block variant="text" @click="managePermissions(item.id)">
                Manage permissions
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn
                :href="'users/' + item.username + '?asUser=' + item.id"
                block
                variant="text"
              >
                Login as user
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn block variant="text" @click="changePassword(item.id)">
                Change password
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn
                block
                variant="text"
                data-target="user-log"
                :data-user-id="item.id"
              >
                Show logs
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn :href="'/user/punch/' + item.id" block variant="text">
                Punches
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn
                id="btn-delete-modal"
                block
                variant="text"
                delelement="user"
                :elementid="item.id"
              >
                Remove
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </VDataTableServer>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import PermissionsForm from '@/components/form/user/PermissionsForm.vue';
import ChangePasswordForm from '@/components/form/user/ChangePasswordForm.vue';
import UserForm from '@/components/form/user/UserForm.vue';
import usePaginationTable from '@/composables/table/table-pagination.js';
import { getUsers } from '@/services/api/user.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { ref } from 'vue';

const search = ref({
  username: null,
});
const userId = ref(null);
const userFormDialog = ref(false);
const loadingUserForm = ref(false);
const managePermissionsDialog = ref(false);
const changePasswordDialog = ref(false);
const loadingChangePassword = ref(false);

const headers = [
  { title: 'ID', key: 'id' },
  { title: 'Username', key: 'username' },
  { title: 'Name', key: 'name', sortable: false },
  { title: 'Last login', key: 'last_login' },
  { title: 'Password', key: 'weak_password' },
  { title: 'Verified', key: 'verified' },
  { title: 'Roles', key: 'roles', sortable: false },
  { title: 'Permissions', key: 'permissions', sortable: false },
  { title: 'Departments', key: 'departments', sortable: false },
  { title: 'Cases', key: 'cases', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'end' },
];

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers,
  sortBy: [{ key: 'last_login', order: 'desc' }],
  fetchRequest: getUsers,
  prepareSearch() {
    return {
      ...search.value,
    };
  },
});

/**
 * @param {object} user
 * @returns {boolean}
 */
function checkCanManageAllDepartments(user) {
  return user.permissions.includes('admin.manage_all_departments');
}

/**
 * @param {string} string
 * @param {number} length
 * @returns {string}
 */
function stringLength(string, length = 15) {
  return string.length > length ? `${string.substring(0, length)}...` : string;
}

/**
 * @param {number} userId
 */
function changePassword(userId) {
  userId.value = userId;
  changePasswordDialog.value = true;
}

/**
 * @param {number} _userId
 */
function managePermissions(_userId) {
  userId.value = _userId;
  managePermissionsDialog.value = true;
}

fetchItems();
</script>
