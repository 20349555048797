import { reactive } from 'vue';

export const messages = reactive([]);

const pushMessage = (msg, options = {}) => {
  const key = Math.random().toString(36).slice(2, 11);

  setTimeout(() => {
    messages.splice(
      messages.findIndex((m) => m.key === key),
      1
    );
  }, options.timeout || 4000);

  messages.unshift({
    key: key,
    value: true,
    closable: true,
    text: msg,
    color: options.color,
    icon: options.icon,
    type: options.type,
  });
};

export const pushError = (msg, options = {}) => {
  pushMessage(msg, { type: 'error', ...options });
};

export const pushSuccess = (msg, options = {}) => {
  pushMessage(msg, { type: 'success', ...options });
};

export const pushWarning = (msg, options = {}) => {
  pushMessage(msg, { type: 'warning', ...options });
};

export const pushInfo = (msg, options = {}) => {
  pushMessage(msg, { type: 'info', ...options });
};
