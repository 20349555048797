<template>
  <v-card :class="{ 'd-print-none': !tableAttrs.items.length }">
    <v-data-table-server
      v-bind="tableAttrs"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Impounding costs</v-col>

            <v-col cols="12" md="6" class="text-right d-print-none">
              <v-btn
                v-tooltip="'Search'"
                variant="text"
                type="submit"
                form="impoundFilter"
                size="small"
                color="blue"
                icon="mdi-magnify"
              />

              <BaseModal
                v-if="
                  $gates.hasPermission('impound.export.costs') &&
                  $gates.hasPermission('user.is_employee')
                "
                actions
                title="Did you want to export the data?"
                @confirm="exportExcel"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-tooltip="'Export'"
                    variant="text"
                    size="small"
                    color="green"
                    icon="mdi-file-excel"
                    v-bind="props"
                  />
                </template>
              </BaseModal>

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                variant="text"
                size="small"
                icon="mdi-filter"
                :color="filterExpand ? 'orange' : 'blue'"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="impoundFilter"
              class="d-print-none"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="search.ref_num" label="Ref. no." />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.billable"
                    :items="billableOptions"
                    label="Billable"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="search.date_min"
                    type="date"
                    class="mr-2"
                    label="Date min"
                    prefix=">="
                    clearable
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="search.date_max"
                    type="date"
                    label="Date max"
                    prefix="<="
                    clearable
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template #[`item.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('impounds.show', item.impound.id)"
        >
          {{ item.impound.ref_num }}
        </a>
      </template>

      <template #[`item.principal`]="{ item }">
        <PeopleLink
          v-if="item.impound.person"
          :person="item.impound.person"
          bold
        />
        <DepartmentLink
          v-if="item.impound.department"
          :department="item.impound.department"
          bold
        />
      </template>

      <template #[`item.vehicle`]="{ item }">
        {{ vehicleText(item.vehicle) || 'Not linked yet' }}
        <v-tooltip
          activator="parent"
          location="top"
          :text="vehicleTooltip(item.vehicle) || 'Not linked yet'"
        />
      </template>

      <template #[`item.invoice`]="{ item }">
        <a
          v-if="item.invoice"
          class="text-reset font-weight-bold text-nowrap"
          :href="'/invoice/' + item.invoice.id"
        >
          {{ item.invoice.invoice_number }}
        </a>
        <span v-else> {{ item.billable ? '-' : 'No billable' }} </span>
      </template>

      <template #[`item.service`]="{ item }">
        {{ item.service.name }}
      </template>

      <template #[`item.price`]="{ item }">
        {{ formatMoney(item.price) }}
      </template>

      <template #[`item.date`]="{ item }">
        {{ formatDate(item.date, 'shortDate') }}
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import BaseModal from '@/components/general/BaseModal.vue';

import usePaginationTable from '@/composables/table/table-pagination.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { formatMoney } from '@/utils/formatter/number.js';
import { getImpoundCosts } from '@/services/api/impound.js';
import { ref } from 'vue';

const filterExpand = ref(false);

const billableOptions = [
  { title: 'Both', value: null },
  { title: 'Billable', value: 1 },
  { title: 'Not billable', value: 0 },
];

const search = ref({
  ref_num: null,
  billable: null,
  date_min: null,
  date_max: null,
});

const headers = [
  { title: 'Ref. no.', key: 'ref_num', sortable: false },
  { title: 'Principal', key: 'principal', sortable: false },
  { title: 'Vehicle', key: 'vehicle', sortable: false },
  { title: 'Invoice', key: 'invoice', sortable: false },
  { title: 'Service', key: 'service', sortable: false },
  { title: 'Price', key: 'price', align: 'end', nowrap: true },
  { title: 'Date', key: 'date', align: 'end', nowrap: true },
];

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers: headers,
  sortBy: [{ key: 'date', order: 'desc' }],
  fetchRequest: getImpoundCosts,
  prepareSearch() {
    return {
      ...search.value,
    };
  },
});

function exportExcel() {
  let url = new URL(
    route('impounds.lists.costs.export'),
    window.location.origin
  );

  getImpoundCosts();

  Object.keys(search.value).forEach((key) => {
    if (!['sortBy'].includes(key) && search.value[key]) {
      url.searchParams.append(key, search.value[key]);
    }
  });

  open(url, '_blank');
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleText(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.make}; ${vehicle.model}; ${vehicle.color}; (${vehicle.year})`;
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleTooltip(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.chassis_number}; ${vehicle.engine_number}`;
}
</script>
