<template>
  <v-card>
    <BaseModal v-model="destroyModal" actions @confirm="sendToDestroy">
      <v-card-title>Send to destroy</v-card-title>

      <v-card-text>
        <ul>
          <li>Vehicle will be released from storage.</li>
          <li>
            A new charge to tow the vehicle to the destruction site will be
            created.
          </li>
          <li>A new task/step will be created to destroy the vehicle.</li>
        </ul>
      </v-card-text>
    </BaseModal>

    <BaseModal v-model="transferModal" actions @confirm="makeTransfer">
      <v-card-title>Make transfer</v-card-title>

      <v-card-text>
        <ul>
          <li>Vehicle will be released from storage.</li>
          <li>
            A new charge to tow the vehicle to the transfer site will be
            created.
          </li>
          <li>A new storage will be created.</li>
        </ul>
      </v-card-text>
    </BaseModal>

    <v-card-title class="text-center">
      Confiscation information

      <span class="float-right">
        <v-menu>
          <template #activator="{ props: aProps }">
            <v-btn icon="mdi-dots-vertical" v-bind="aProps" variant="text" />
          </template>

          <v-list>
            <v-list-item>
              <v-btn
                variant="text"
                text="Refresh"
                block
                @click="refreshImpound"
              />
            </v-list-item>

            <v-list-item
              v-if="
                !impound.storage?.out_date &&
                impound.status.value > ImpoundStatus.VERIFICATION &&
                impound.status.value < ImpoundStatus.PAID
              "
              v-can="'impound.send_to_destroy'"
            >
              <v-btn
                variant="text"
                text="Send to destroy"
                block
                @click="destroyModal = true"
              />
            </v-list-item>

            <v-list-item
              v-if="
                !impound.storage?.out_date &&
                impound.status.value > ImpoundStatus.VERIFICATION &&
                impound.status.value < ImpoundStatus.PAID
              "
              v-can="'impound.make_transfer'"
            >
              <v-btn
                variant="text"
                text="Make transfer"
                block
                @click="transferModal = true"
              />
            </v-list-item>

            <v-list-item
              v-if="impound.storage_id && !impound.inspection_id"
              v-can="'storage.inspection.create'"
            >
              <v-btn
                variant="text"
                text="Inspection"
                data-target="btn-inspect-vehicle"
                data-ajax-type="new"
                :data-storage-id="impound.storage_id"
                :data-vehicle-id="impound.vehicle_id"
                block
              />
            </v-list-item>

            <v-list-item v-can="'impound.delete'">
              <v-btn
                id="btn-delete-modal"
                color="red"
                variant="text"
                delelement="impound"
                text="Delete"
                :elementid="impound.id"
                block
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-card-title>

    <v-card-text>
      <v-table>
        <tbody>
          <tr>
            <th>Reference number</th>
            <td class="font-weight-bold">
              {{ impound.ref_num }}
            </td>
          </tr>

          <tr>
            <th>Mutatienummer</th>
            <td class="font-weight-bold">
              {{ impound.dossier || 'N/A' }}
            </td>
          </tr>

          <tr>
            <th>Impound type</th>
            <td>
              {{ impound.impoundType }}
            </td>
          </tr>

          <tr>
            <th>Status</th>
            <td>
              <v-chip
                :color="impound.status?.color"
                :text="impound.status?.name"
              />
            </td>
          </tr>

          <tr>
            <th>Car status</th>
            <td>
              <v-chip
                :color="impound.carStatus?.color"
                :text="impound.carStatus?.name"
              />
            </td>
          </tr>

          <tr>
            <th>Client</th>
            <td>
              <PeopleLink v-if="impound.person" :person="impound.person" bold />
              <DepartmentLink
                v-else-if="impound.department"
                :department="impound.department"
                dep
                bold
              />
            </td>
          </tr>

          <tr v-if="impound.authorizedBy">
            <th>Authorized by</th>
            <td>
              <PeopleLink :person="impound.authorizedBy" bold />
            </td>
          </tr>

          <tr v-if="impound.impoundBy">
            <th>Carried out by</th>
            <td>
              <UserLink :user="impound.impoundBy" bold />
            </td>
          </tr>

          <tr v-if="impound.reason">
            <th>Reason for confiscation</th>
            <td>{{ impound.reason.code }} - {{ impound.reason.title }}</td>
          </tr>

          <tr v-if="impound.towBy">
            <th>Towing done by</th>
            <td>
              <CompanyLink :company="impound.towBy" />
            </td>
          </tr>

          <tr v-if="impound.towVehicle">
            <th>Tow truck</th>
            <td>
              {{ vehicleText(impound.towVehicle) }}
              <v-tooltip
                activator="parent"
                location="top"
                :text="vehicleTooltip(impound.towVehicle)"
              />
            </td>
          </tr>

          <tr v-if="impound.towDriver">
            <th>Tow driver</th>
            <td>
              <PeopleLink :person="impound.towDriver" bold />
            </td>
          </tr>

          <tr v-if="impound.call_towing_at">
            <th>Towing call at</th>
            <td>{{ formatDate(impound.call_towing_at, 'fullDateTime') }}</td>
          </tr>

          <tr v-if="impound.pickup_at">
            <th>Pickup date and time</th>
            <td>{{ formatDate(impound.pickup_at, 'fullDateTime') }}</td>
          </tr>

          <tr v-if="impound.delivery_at">
            <th>Delivery date and time</th>
            <td>{{ formatDate(impound.delivery_at, 'fullDateTime') }}</td>
          </tr>

          <tr v-if="impound.pickupStreet">
            <th>Pickup address</th>
            <td>
              {{ impound.pickupStreet.name }},
              {{ impound.pickupStreet.city.name }}
              {{
                impound.pickup_house_number &&
                '# ' + impound.pickup_house_number
              }}
            </td>
          </tr>

          <tr v-if="impound.deliveryStreet">
            <th>Delivery address</th>
            <td>
              {{ impound.deliveryStreet.name }},
              {{ impound.deliveryStreet.city.name }}
              {{
                impound.delivery_house_number &&
                '# ' + impound.delivery_house_number
              }}
            </td>
          </tr>

          <tr>
            <th>Date taken</th>
            <td>{{ formatDate(impound.date, 'fullDate') }}</td>
          </tr>

          <template v-if="impound.debt">
            <tr>
              <th>Collection</th>
              <td class="font-weight-bold">
                <a
                  class="text-reset"
                  :href="route('debts.show', [impound.debt.id])"
                >
                  {{ impound.debt.ref_num }}
                </a>
              </td>
            </tr>
          </template>

          <template v-if="impound.storage">
            <tr>
              <th>Storage</th>
              <td class="font-weight-bold">
                <a
                  class="text-reset"
                  :href="route('storages.show', [impound.storage.id])"
                >
                  {{ impound.storage.ref_num }}
                </a>
              </td>
            </tr>

            <tr>
              <th>Storage location</th>
              <td>{{ impound.storage.location || 'RSI' }}</td>
            </tr>

            <tr>
              <th>Storage date</th>
              <td>{{ formatDate(impound.storage.in_date, 'fullDate') }}</td>
            </tr>

            <tr>
              <th>Release date</th>
              <td>
                {{
                  impound.storage.out_date
                    ? formatDate(impound.storage.out_date, 'fullDate')
                    : 'Still in storage'
                }}
              </td>
            </tr>

            <tr v-if="impound.inspection_id">
              <th>Inspection</th>
              <td>
                <span
                  v-can="'vehicle.inspection.view'"
                  class="font-weight-bold cursor-pointer"
                  data-target="btn-inspect-vehicle"
                  data-ajax-type="show"
                  :data-inspect-id="impound.inspection_id"
                >
                  Open inspection
                </span>
              </td>
            </tr>
          </template>

          <tr v-if="impound.owner">
            <th>Owner</th>
            <td>
              <PeopleLink :person="impound.owner" bold />
            </td>
          </tr>

          <tr v-if="impound.driver || impound.owner_is_driver">
            <th>Driver</th>
            <td>
              <PeopleLink v-if="impound.driver" :person="impound.driver" bold />
              <span v-else>The owner was the driver.</span>
            </td>
          </tr>

          <tr>
            <th>Register date (RIP)</th>
            <td>{{ formatDate(impound.created_at, 'fullDateTime') }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import UserLink from '@/components/links/UserLink.vue';
import CompanyLink from '@/components/links/CompanyLink.vue';
import BaseModal from '@/components/general/BaseModal.vue';

import { impound, fetchImpound } from '@/store/storage/impound.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import {
  sendToDestroyVehicle,
  makeTransferVehicle,
} from '@/services/api/impound.js';

import { ref } from 'vue';

const ImpoundStatus = Object.freeze({
  ONGOING_TOWING: 1,
  DOING_REGISTRATION: 2,
  DOING_INSPECTION: 3,
  VERIFICATION: 4,
  COLLECTION_CASE_OPEN: 5,
  NOFICATION_LETTER_SENT: 6,
  NOFICATION_LETTER_RECEIVED: 7,
  PAYING: 8,
  PAID: 9,
  DESTROYED: 10,
  CLOSED: 11,
});

const destroyModal = ref(false);
const transferModal = ref(false);

const emit = defineEmits(['updated']);

function refreshImpound() {
  if (impound.id) {
    fetchImpound(impound.id);
  }
}

function sendToDestroy() {
  sendToDestroyVehicle(impound.id).then(() => {
    fetchImpound(impound.id);
    emit('updated');
  });
}

function makeTransfer() {
  makeTransferVehicle(impound.id).then(() => {
    fetchImpound(impound.id);
    emit('updated');
  });
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleText(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.make}; ${vehicle.model}; ${vehicle.color}; (${vehicle.year})`;
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleTooltip(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.chassis_number}; ${vehicle.engine_number}`;
}
</script>
