import axios from 'axios';
import { pushError } from '@/composables/theme/alertMessages.js';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function getDefaultMessage(error) {
  return error.response?.data?.message || error.message;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 429) {
      pushError(
        getDefaultMessage(error) || 'Too many requests. Please try again later.'
      );
    } else if (error.response?.status === 401) {
      pushError(getDefaultMessage(error) || 'Unauthorized. Please log in.');
    } else if (error.response?.status === 403) {
      pushError(
        getDefaultMessage(error) ||
          'Forbidden. You are not allowed to access this resource.'
      );
      ('Forbidden. You are not allowed to access this resource.');
    } else if (getDefaultMessage(error)) {
      pushError(getDefaultMessage(error));
    }
    // Return the error to be handled by the calling function
    return Promise.reject(error);
  }
);

export default axios;
