<template>
  <v-card>
    <v-card-title class="text-center">
      <span class="float-left d-print-none">
        <v-btn
          v-if="history.length"
          v-can="'impound.request.history'"
          v-tooltip="'See history'"
          variant="text"
          size="small"
          color="orange"
          append-icon="mdi-history"
          text="Has been modified"
          @click="
            currentHistoryIndex = 0;
            openHistoryModal = true;
          "
        />
      </span>

      Request Data

      <span class="float-right d-print-none">
        <v-btn
          v-can="'impound.request.update'"
          v-tooltip="'Edit'"
          variant="text"
          size="small"
          color="blue"
          icon="mdi-pencil"
          @click="editRequestModal = true"
        />
      </span>

      <BaseModal v-model="editRequestModal" size="xl">
        <v-card-title>Edit Request</v-card-title>

        <v-card-text>
          <RequestImpoundForm
            v-model:submitting="submittingRequest"
            v-if="impound.id"
            :request="impound.request"
            :impound-id="impound.id"
            @updated="refreshCard"
          />

          <v-overlay
            v-model="submittingRequest"
            contained
            class="align-center justify-center"
          >
            <v-progress-circular color="primary" indeterminate size="100" />
          </v-overlay>
        </v-card-text>
      </BaseModal>

      <BaseModal v-model="openHistoryModal">
        <v-card-title class="text-center">
          <v-btn
            v-if="currentHistoryIndex < history.length - 1"
            class="float-left"
            icon="mdi-arrow-left"
            @click="goBack"
          />

          History

          <v-btn
            v-if="currentHistoryIndex > 0"
            class="float-right"
            icon="mdi-arrow-right"
            @click="goForward"
          />
        </v-card-title>

        <v-card-text>
          By: <UserLink :user="history[currentHistoryIndex].updatedBy" bold />
          <br />
          At: {{ formatDate(history[currentHistoryIndex].updated_at) }}

          <v-divider />

          <v-table>
            <thead>
              <tr>
                <th>Field</th>
                <th>Old value</th>
                <th>New value</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="key in Object.keys(history[currentHistoryIndex].new)"
                :key="key"
              >
                <td>{{ key }}</td>
                <td class="text-red">
                  {{ history[currentHistoryIndex].old[key] }}
                </td>
                <td class="text-green">
                  {{ history[currentHistoryIndex].new[key] }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </BaseModal>
    </v-card-title>

    <v-card-text>
      <v-col v-if="impound.request" cols="12">
        <v-table>
          <thead>
            <tr>
              <th>Address</th>
              <th class="text-right">Reason</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                {{ impound.request.address || '-' }}
                {{ impound.request.house_number }}
              </td>
              <td class="text-right">{{ impound.request.reason || '-' }}</td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th>Impound by</th>
              <th class="text-right">Department</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{{ impound.request.impound_by || '-' }}</td>
              <td class="text-right">
                {{ impound.request.impound_by_department || '-' }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>

      <v-col v-if="impound.request?.vehicle" cols="12">
        <v-table class="text-center">
          <thead>
            <tr>
              <th class="text-left">Make</th>
              <th class="text-center">Model</th>
              <th class="text-center">Year</th>
              <th class="text-center">Color</th>
              <th class="text-center">Chassis number</th>
              <th class="text-right">License Plate</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td class="text-left">
                {{ impound.request.vehicle.make || '-' }}
              </td>
              <td>{{ impound.request.vehicle.model || '-' }}</td>
              <td>{{ impound.request.vehicle.year || '-' }}</td>
              <td>{{ impound.request.vehicle.color || '-' }}</td>
              <td>{{ impound.request.vehicle.chassis_number || '-' }}</td>
              <td class="text-right">
                {{ impound.request.vehicle.plate_number || '-' }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>

      <v-col v-if="impound.request?.owner" cols="12">
        <v-table>
          <thead>
            <tr>
              <th>Owner name</th>
              <th class="text-right">Phone</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{{ impound.request.owner.name || '-' }}</td>
              <td class="text-right">
                {{ impound.request.owner.contact_number || '-' }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>

      <v-col
        v-if="impound.request?.driver && !impound.request?.owner_is_driver"
        cols="12"
      >
        <v-table>
          <thead>
            <tr>
              <th>Driver name</th>
              <th class="text-right">Phone</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{{ impound.request.driver.name || '-' }}</td>
              <td class="text-right">
                {{ impound.request.driver.contact_number || '-' }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>

      <v-col
        v-if="impound.request?.owner_is_driver"
        cols="12"
        class="text-center"
      >
        The client declare that he is the driver as well.
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script setup>
import RequestImpoundForm from '@/components/form/impound/RequestImpoundForm.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import UserLink from '@/components/links/UserLink.vue';
import { pushSuccess } from '@/composables/theme/alertMessages';
import { getRequestHistory } from '@/services/api/impound.js';
import { impound, fetchImpound } from '@/store/storage/impound.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { ref, watch } from 'vue';

const history = ref([]);
const editRequestModal = ref(false);
const openHistoryModal = ref(false);
const currentHistoryIndex = ref(0);
const submittingRequest = ref(false);

watch(impound, () => {
  getHistory();
});

function getHistory() {
  if (!impound.id) {
    return;
  }

  getRequestHistory(impound.id).then((_history) => {
    history.value = _history;
    console.log(_history);
  });
}

function refreshCard() {
  pushSuccess('Impound request updated');
  fetchImpound(impound.id);
  editRequestModal.value = false;
  getHistory();
}

function goBack() {
  if (currentHistoryIndex.value < history.value.length - 1) {
    currentHistoryIndex.value++;
  }
}

function goForward() {
  if (currentHistoryIndex.value > 0) {
    currentHistoryIndex.value--;
  }
}

getHistory();
</script>
