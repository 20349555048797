<template>
  <v-row>
    <v-col cols="12" sm="8" lg="4">
      <StorageInfoCard :storage-id="storageId" />
    </v-col>

    <v-col cols="12" sm="4" lg="4">
      <VehicleInfoCard
        v-if="storage.vehicle_id"
        :vehicle-id="storage.vehicle_id"
      />
    </v-col>

    <v-col cols="12" lg="4">
      <v-row>
        <v-col cols="12" md="6" lg="12">
          <AttachmentCard
            v-if="storageId"
            :section-id="storageId"
            section="storage"
            hide-empty
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          lg="12"
          :class="{ 'd-print-none': costsCounter || storage?.impound }"
        >
          <SectionCostCard
            v-if="storageId && !storage?.impound"
            v-model:items-counter="costsCounter"
            :section-id="storageId"
            section="storage"
          />

          <v-card v-if="storage?.impound?.debt">
            <v-card-title class="text-center"> Charges </v-card-title>
            <v-card-text>
              All charges have to be booked in the collection case.
              <br />
              Collection:
              <a
                :href="route('debts.summaries.index', storage.impound.debt.id)"
                class="font-weight-bold text-reset"
              >
                {{ storage.impound.debt.ref_num }}
              </a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      lg="6"
      :class="{ 'd-print-none': invoicesCounter || storage?.impound }"
    >
      <SectionInvoiceCard
        v-if="storageId && !storage?.impound"
        v-model:items-counter="invoicesCounter"
        :section-id="storageId"
        section="storage"
      />

      <v-card v-if="storage?.impound?.debt">
        <v-card-title class="text-center"> Invoices </v-card-title>

        <v-card-text>
          All invoices have to be booked in the collection case.
          <br />
          Collection:
          <a
            :href="route('debts.actions.index', storage.impound.debt.id)"
            class="font-weight-bold text-reset"
          >
            {{ storage.impound.debt.ref_num }}
          </a>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" lg="6" :class="{ 'd-print-none': notesCounter }">
      <SectionNoteCard
        v-if="storageId"
        v-model:items-counter="notesCounter"
        :section-id="storageId"
        section="storage"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import AttachmentCard from '@/components/card/general/AttachmentCard.vue';
import VehicleInfoCard from '@/components/card/vehicle/VehicleInfoCard.vue';
import StorageInfoCard from '@/components/card/storage/StorageInfoCard.vue';
import SectionNoteCard from '@/components/card/general/SectionNoteCard.vue';
import SectionInvoiceCard from '@/components/card/invoice/SectionInvoiceCard.vue';
import SectionCostCard from '@/components/card/general/SectionCostCard.vue';

import { storage, fetchStorage } from '@/store/storage/storage.js';
import { onMounted, ref } from 'vue';

const { storageId } = defineProps({
  storageId: {
    type: Number,
    required: true,
  },
});

const costsCounter = ref(0);
const invoicesCounter = ref(0);
const notesCounter = ref(0);

onMounted(() => {
  fetchStorage(storageId);
});
</script>
