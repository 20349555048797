<template>
  <v-row>
    <v-col v-if="impound?.request.tow_to_rsi" class="text-center" cols="12">
      Client certified that the vehicle will be towed to RSI.
    </v-col>

    <v-col cols="12">
      <v-checkbox
        v-model="model.storage_rsi"
        :error-messages="errors?.storage_rsi"
        label="Storage manage by RSI?"
      />
    </v-col>

    <v-col v-if="model.storage_rsi" cols="12">
      <v-select
        v-model="model.storage_location"
        :items="storageOptions"
        :error-messages="errors?.storage_location"
        label="Storage location"
      />
    </v-col>

    <template v-if="!model.storage_rsi">
      <v-col cols="12">
        <v-autocomplete
          v-bind="streetsAttrs"
          aria-autocomplete="none"
          :error-messages="errors?.delivery_street_id"
          label="Storage address"
          clearable
          v-on="streetsListeners"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="model.delivery_house_number"
          :error-messages="errors?.delivery_house_number"
          label="House number"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script setup>
import { impound } from '@/store/storage/impound.js';
import { useStreetAutocomplete } from '@/composables/autocomplete/street-autocomplete.js';

import { stepProps } from '@/composables/task/stepOptions.js';
import { watch } from 'vue';

defineProps({
  ...stepProps,
});

const storageOptions = [
  'RSI',
  'Police station Shaba',
  'Police station Oranjestad',
  'Police station San Nicolas',
  'Police station Macuarima',
  'Police garage Balashi',
];

const { attrs: streetsAttrs, listeners: streetsListeners } =
  useStreetAutocomplete({ minSearchLength: 1 });

const model = defineModel({ required: true, type: Object });
model.value.storage_rsi = true;

watch(
  () => streetsAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.delivery_street_id = value;
    }
  }
);
</script>
