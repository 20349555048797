<template>
  <v-row>
    <v-col cols="12" md="6">
      <ImpoundInfoCard :impound-id="impoundId" />
    </v-col>

    <v-col cols="12" md="6">
      <ImpoundRequestDataCard />

      <br />

      <VehicleInfoCard
        v-if="impound.vehicle_id"
        :vehicle-id="impound.vehicle_id"
      />

      <br />

      <SectionCostCard
        v-if="impoundId && !loading"
        :section-id="impoundId"
        no-button
        section="impound"
      />
    </v-col>

    <v-col cols="12">
      <TaskStepListCard
        section="impound"
        :section-id="impoundId"
        @step-completed="fetchImpound(impoundId)"
        @rollback-step="fetchImpound(impoundId)"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import ImpoundInfoCard from '@/components/card/storage/ImpoundInfoCard.vue';
import ImpoundRequestDataCard from '@/components/card/storage/ImpoundRequestDataCard.vue';
import VehicleInfoCard from '@/components/card/vehicle/VehicleInfoCard.vue';
import TaskStepListCard from '@/components/card/general/TaskStepListCard.vue';
import SectionCostCard from '@/components/card/general/SectionCostCard.vue';

import { impound, fetchImpound, loading } from '@/store/storage/impound.js';

const { impoundId } = defineProps({
  impoundId: {
    type: Number,
    required: true,
  },

  vehicleId: {
    type: Number,
    default: null,
  },
});

fetchImpound(impoundId);
</script>
