import axios from '../../../plugins/axios.js';
import Mustache from 'mustache';
import clientList from './debt-client-list.mustache?raw';
import { emptyHTMLContent } from '../../functions/html-handler.js';
import { moneyFormat } from '../../functions/filters.js';
import {
  calculateTotalPaymentPercentage,
  creditorsInfo,
  debtorsInfo,
} from '../../../templates/general/debt.js';

const debtsClientTableRow = (debt) => {
  // ref_num and Dossier
  let statusColor = debt.status_color || `table-${debt.status_color}`;

  let latePaymentColor = '';
  if (debt.late_payment > 0.005 && debt.first_agreement) {
    latePaymentColor = 'text-success';
  } else if (debt.late_payment < -0.005 && debt.first_agreement) {
    latePaymentColor = 'text-danger';
  }

  let reserve =
    parseFloat(debt.total_payment || 0) -
    parseFloat(debt.distribution_client || 0) -
    parseFloat(debt.distribution_rsi || 0);

  let totalPaymentPercentage = calculateTotalPaymentPercentage(debt);
  return Mustache.render(clientList, {
    debt,
    statusColor,
    latePaymentColor,
    reserve,
    moneyFormat() {
      return (price, render) => moneyFormat(render(price));
    },
    creditorsInfo: () => creditorsInfo(debt),
    debtorsInfo: () => debtorsInfo(debt),
    totalPaymentPercentage,
  });
};

export default (page = 1) => {
  let $page = document.getElementById('debts-client-filter-page-number');
  let $loader = document.getElementsByClassName('debts-client-data-loader');
  let $table = document.getElementById('table-debts-client-data');

  return new Promise((response, reject) => {
    show($loader);
    if (page === 1) {
      emptyHTMLContent($debtsClientTable);
    }
    axios
      .post('/debts/list/client-search?page=' + page, {
        ref_num: getValueById('debt-filter-ref-num'),
        dossier: getValueById('debt-filter-dossier'),
        debtor_person_id: getValueById('debt-filter-debtor-person-id'),
        debtor_department_id: getValueById('debt-filter-debtor-department-id'),
        creditor_person_id: getValueById('debt-filter-creditor-person-id'),
        creditor_department_id: getValueById(
          'debt-filter-creditor-department-id'
        ),
        date_min: getValueById('debt-filter-period-date-min'),
        date_max: getValueById('debt-filter-period-date-max'),
      })
      .then(({ data }) => {
        data.data.map((debt) => {
          $table.insertAdjacentHTML('beforeend', debtsClientTableRow(debt));
        });

        // Page en/dis-able loader
        $page.value = data.current_page + 1;
        loadDebtClientFilter = !!data.next_page_url;
        response(true);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => hide($loader));
  });
};
