<template>
  <v-card>
    <v-card-title class="text-center">
      <span v-if="section === 'storage'" class="float-left d-print-none">
        <v-btn
          v-can="'storage.invoice.create'"
          v-tooltip="'Bill additional costs'"
          :data-storage-id="sectionId"
          data-target="btn-invoice"
          data-ajax-type="newSE"
          icon="mdi-receipt-text-plus"
          variant="text"
        />
      </span>

      Charges

      <span v-if="!noButton" class="float-right d-print-none">
        <v-btn
          v-can="section + '.cost.create'"
          v-tooltip="'Add cost'"
          data-ajax-type="new"
          icon="mdi-cash-plus"
          variant="text"
          v-bind="createBtnProps"
        />
      </span>
    </v-card-title>

    <v-card-text>
      <SectionCostTable
        v-model:items-counter="itemsCounter"
        :section-id="sectionId"
        :section="section"
        :no-options="noButton"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import SectionCostTable from '@/components/tables/general/SectionCostTable.vue';

import { computed } from 'vue';

const itemsCounter = defineModel('itemsCounter', {
  type: Number,
  default: 0,
});

const { section, sectionId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },

  noButton: {
    type: Boolean,
    default: false,
  },
});

// bridge for legacy code
const createBtnProps = computed(() => {
  switch (section) {
    case 'storage':
      return {
        'data-storage-id': sectionId,
        'data-target': 'btn-storage-cost',
      };

    default:
      return {};
  }
});
</script>
