import { ref, computed, watch } from 'vue';

const theme = ref(localStorage.getItem('theme') || 'light');

const icon = computed(() => {
  return theme.value === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night';
});

watch(theme, (value) => {
  localStorage.setItem('theme', value);
});

/**
 * @returns {object} theme, icon, changeTheme
 */
export default function useThemeColor() {
  function changeTheme() {
    theme.value = theme.value === 'light' ? 'dark' : 'light';
  }

  return {
    theme,
    icon,
    changeTheme,
  };
}
