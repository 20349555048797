<template>
  <div>
    <v-card
      class="mx-auto pa-12 pb-8"
      elevation="8"
      max-width="448"
      rounded="lg"
      :loading
    >
      <v-img
        class="mx-auto mb-6"
        max-width="228"
        src="/resources/images/RSI_logo.png"
      />

      <v-alert v-if="errorMessage" type="error" :text="errorMessage" />

      <div class="text-subtitle-1 text-medium-emphasis">Account</div>

      <v-form @submit.prevent="submit">
        <v-text-field
          v-model="form.email"
          class="mb-4"
          placeholder="Email address or username"
          prepend-inner-icon="mdi-email-outline"
          :error-messages="errors.email"
        />

        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
        >
          Password

          <a
            class="text-caption text-decoration-none text-blue"
            href="/forgot-password"
          >
            Forgot login password?
          </a>
        </div>

        <v-text-field
          v-model="form.password"
          class="mb-4"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          :error-messages="errors.password"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          @click:append-inner="visible = !visible"
        />

        <v-btn
          type="submit"
          class="my-8"
          size="large"
          variant="tonal"
          block
          :loading
          :disabled
        >
          Log In
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { login } from '@/services/api/user.js';
import { useErrorsForm } from '@/composables/form/errorsForm.js';

const visible = ref(false);
const disabled = ref(false);
const form = ref({
  email: '',
  password: '',
});
const loading = ref(false);

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

function submit() {
  loading.value = true;
  clearErrors();

  login(form.value)
    .then((url) => {
      disabled.value = true;
      window.location.href = url;
    })
    .catch((error) => {
      if (!setErrorsFromResponse(error)) {
        return;
      }

      throw error;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
