<template>
  <v-container>
    <v-card class="p-3">
      <v-card-title class="text-center">
        RECOVERY SOLUTIONS & INVESTIGATIONS (RSI)
      </v-card-title>

      <v-row>
        <v-col cols="12">
          <h2 class="text-center">Our Services</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(service, index) in services"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card class="pa-2 m-2" variant="tonal">
            <v-card-title class="text-center">
              <v-icon size="34">{{ service.icon }}</v-icon>
            </v-card-title>

            <v-card-text class="text-center">
              <div class="font-weight-bold">{{ service.title }}</div>
              {{ service.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script setup>
const services = [
  {
    icon: 'mdi-cash',
    title: 'Debt collections',
    description: 'Efficient and legal debt recovery solutions',
  },
  {
    icon: 'mdi-file-document',
    title: 'Regular collections',
    description: 'Routine follow-ups on outstanding payments',
  },
  {
    icon: 'mdi-archive',
    title: 'Inventory management',
    description: 'Keep track of assets and goods',
  },
  {
    icon: 'mdi-account-group',
    title: 'Mediation',
    description: 'Resolving disputes between parties',
  },
  {
    icon: 'mdi-email',
    title: 'Letter delivery',
    description: 'Secure and professional delivery service',
  },
  {
    icon: 'mdi-car',
    title: 'Vehicle storage',
    description: 'Safe storage for impounded or seized vehicles',
  },
  {
    icon: 'mdi-car-key',
    title: 'Vehicle repossession',
    description: 'Lawful retrieval of unpaid vehicles',
  },
  {
    icon: 'mdi-home-remove',
    title: 'Voluntary evictions',
    description: 'Support for property clearance',
  },
  {
    icon: 'mdi-magnify',
    title: 'Investigations',
    description: 'Private and corporate investigative services',
  },
  {
    icon: 'mdi-lock',
    title: 'Private property impounding',
    description: 'Secure storage for private properties',
  },
  {
    icon: 'mdi-vote',
    title: 'Election-related services',
    description: 'Assistance with electoral processes',
  },
  {
    icon: 'mdi-scale-balance',
    title: 'Assistance in insolvency cases',
    description: 'Legal support for financial cases',
  },
];
</script>
