<template>
  <v-responsive>
    <v-app :theme="theme" class="bg-red-gradient">
      <v-app-bar prominent class="d-print-none">
        <v-img
          src="/resources/images/RSI_icon.png"
          class="pl-4"
          max-width="60"
          href="/"
        />
        <v-btn href="/">Home</v-btn>
        <v-btn href="/sales">Sale</v-btn>
        <v-spacer></v-spacer>
        <v-btn :prepend-icon="icon" @click="changeTheme" />
        <v-btn :href="route('login')">Login</v-btn>
      </v-app-bar>

      <v-main>
        <slot />
      </v-main>

      <GuestFooter />
    </v-app>
  </v-responsive>
</template>

<script setup>
import GuestFooter from '@/components/general/GuestFooter.vue';
import useThemeColor from '@/composables/theme/themeColor.js';

const { theme, icon, changeTheme } = useThemeColor();
</script>
