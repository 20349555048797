<template>
  <a :class="color" :href="url">
    <span class="d-inline-block">
      {{ person.firstname }}
      {{ middleName ? person.middlename : '' }}
    </span>
    <span v-if="alias" class="d-inline-block">
      {{ person.alias }}
    </span>
    <span :class="{ 'd-inline-block': true, 'font-weight-bold': bold }">
      {{ person.lastname }}
    </span>
  </a>
</template>

<script setup>
import { computed, inject } from 'vue';

const $gates = inject('$gates');

const { person } = defineProps({
  person: {
    type: Object,
    required: true,
  },
  middleName: {
    type: Boolean,
    default: false,
  },
  alias: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: null,
  },
});

const url = computed(() => {
  if ($gates.hasPermission('person.view')) {
    return route('people.show', [person.person_id]);
  }

  return null;
});
</script>
