<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-center">
            <h2>Request Impound</h2>
          </v-card-title>

          <v-card-text>
            <RequestImpoundForm v-model:submitting="submitting" />
          </v-card-text>

          <v-overlay
            :model-value="submitting"
            class="align-center justify-center"
            contained
          >
            <v-progress-circular color="primary" indeterminate size="100" />
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import RequestImpoundForm from '@/components/form/impound/RequestImpoundForm.vue';
import { ref } from 'vue';

const submitting = ref(false);
</script>
