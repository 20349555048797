import { ref, watch } from 'vue';

const localDrawer = localStorage.getItem('drawer');

const drawer = ref(localDrawer == null || localDrawer === 'true');

watch(drawer, (value) => {
  localStorage.setItem('drawer', value);
});

export default function useDrawerStatus() {
  return {
    drawer,
    toggleDrawer: () => {
      drawer.value = !drawer.value;
    },
  };
}
