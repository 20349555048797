<template>
  <v-form @submit.prevent="submit">
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <v-alert type="error">{{ errorMessage }}</v-alert>
      </v-col>
    </v-row>

    <v-row v-if="!impoundId">
      <v-col cols="12" md="6">
        <v-select
          v-model="form.department_id"
          autocomplete="off"
          label="Department"
          :items="departments"
          :disabled="departments.length == 1"
          :error-messages="errors.department_id"
        />
      </v-col>

      <v-col v-can="'impound.select_authorizer'" cols="12" sm="8" md="4">
        <v-select
          v-model="form.authorizer"
          autocomplete="off"
          label="Authorize by"
          :items="departmentAuthorizers"
          :error-messages="errors.authorizer"
        />
      </v-col>

      <v-col v-can="'impound.enter_date'" cols="12" sm="4" md="2">
        <v-text-field
          v-model="form.date"
          type="date"
          label="Authorize date"
          :error-messages="errors.date"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h5>Pickup Information</h5>
      </v-col>

      <v-col cols="8">
        <v-combobox
          v-model="streetModel"
          v-model:search="streetSearch"
          autocomplete="off"
          aria-autocomplete="none"
          :items="filteredStreets"
          clearable
          no-filter
          :loading="loadingStreets"
          :error-messages="errors.address"
          item-value="title"
          label="Address"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="form.house_number"
          label="Number"
          :error-messages="errors.house_number"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h5>Impound Information</h5>
      </v-col>

      <v-col cols="6" md="3">
        <v-text-field
          v-model="form.dossier"
          label="Mutatienummer"
          :error-messages="errors.dossier"
        />
      </v-col>

      <v-col cols="6" md="5">
        <v-text-field
          v-model="form.impound_by"
          label="Impound by"
          :error-messages="errors.impound_by"
        />
      </v-col>

      <v-col cols="6" md="4">
        <v-text-field
          v-model="form.impound_by_department"
          label="Department"
          :error-messages="errors.impound_by_department"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="form.impound_type"
          label="Impound type"
          :items="[
            { value: 1, title: 'Storage' },
            { value: 2, title: 'Seizure' },
          ]"
          :error-messages="errors.impound_type"
        />
      </v-col>

      <v-col cols="6">
        <v-checkbox
          v-model="form.tow_to_rsi"
          label="Tow to RSI"
          :error-messages="errors.tow_to_rsi"
        />
      </v-col>

      <v-col cols="12">
        <v-combobox
          v-model="form.reason"
          :items="[
            { value: 'accident', title: 'Accident' },
            { value: 'illegal_parking', title: 'Illegal Parking' },
            { value: 'abandoned', title: 'Abandoned' },
            { value: 'other', title: 'Other' },
          ]"
          :return-object="false"
          :error-messages="errors.reason"
          item-value="title"
          clearable
          label="Reason"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h5>Vehicle Information</h5>
      </v-col>

      <v-col cols="4">
        <v-combobox
          v-model="make"
          :items="makes"
          :error-messages="errors.vehicle?.make"
          clearable
          label="Make"
        />
      </v-col>
      <v-col cols="4">
        <v-combobox
          v-model="form.vehicle.model"
          :items="models"
          :return-object="false"
          :error-messages="errors.vehicle?.model"
          item-value="title"
          clearable
          label="Model"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.vehicle.year"
          :error-messages="errors.vehicle?.year"
          type="number"
          label="Year"
        />
      </v-col>

      <v-col cols="4">
        <v-combobox
          v-model="form.vehicle.type"
          :items="types"
          :return-object="false"
          :error-messages="errors.vehicle?.type"
          item-value="title"
          clearable
          label="Type"
        />
      </v-col>
      <v-col cols="4">
        <v-combobox
          v-model="form.vehicle.color"
          :items="colors"
          :return-object="false"
          :error-messages="errors.vehicle?.color"
          item-value="title"
          clearable
          label="Color"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="form.vehicle.plate_number"
          label="Plate number"
          :error-messages="errors.vehicle?.plate_number"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.vehicle.chassis_number"
          label="Chassis number/VIN"
          :error-messages="errors.vehicle?.chassis_number"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h5>Owner Information</h5>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="form.owner.name"
          :error-messages="errors.owner?.name"
          label="Name"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.owner.contact_number"
          label="Contact number"
          :error-messages="errors.owner?.contact_number"
        />
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-model="form.owner_is_driver"
          label="Owner is the driver"
          :error-messages="errors.owner_is_driver"
        />
      </v-col>
    </v-row>

    <v-row v-if="!form.owner_is_driver">
      <v-col cols="12">
        <h5>Driver Information</h5>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="form.driver.name"
          :error-messages="errors.driver?.name"
          label="Name"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="form.driver.contact_number"
          label="Contact number"
          :error-messages="errors.driver?.contact_number"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" :disabled="submitting" type="submit">
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import { ref, reactive, computed, watch, inject } from 'vue';

import { useStreetAutocomplete } from '@/composables/autocomplete/street-autocomplete.js';
import { requestImpound, updateRequest } from '@/services/api/impound.js';
import {
  getVehicleMakes,
  getVehicleModels,
  getVehicleTypes,
  getVehicleColors,
} from '@/services/api/vehicle.js';
import { useErrorsForm } from '@/composables/form/errorsForm';
import { getDepartmentAuthorizer } from '@/services/api/search';
import { formatInput } from '@/utils/formatter/date';
import { pushSuccess } from '@/composables/theme/alertMessages';

const submitting = defineModel('submitting', {
  type: Boolean,
  default: false,
});

const { impoundId, request } = defineProps({
  impoundId: {
    type: Number,
    default: null,
  },
  request: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['updated', 'created']);

const { gates } = inject('sharedData');

const { streets, streetModel, streetSearch, loadingStreets } =
  useStreetAutocomplete({ minSearchLength: 1 });

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

const make = ref(null);
const $gates = inject('$gates');

const canEnterDate = $gates.hasPermission('impound.enter_date');
const form = reactive({
  department_id: null,
  date: canEnterDate ? formatInput(new Date(), 'date') : null,
  authorizer: null,
  address: null,
  house_number: null,
  vehicle: {
    plate_number: null,
    model: null,
    make: null,
    chassis_number: null,
    color: null,
    type: null,
    year: null,
  },
  owner_is_driver: false,
  owner: {
    name: null,
    contact_number: null,
  },
  driver: {
    name: null,
    contact_number: null,
  },
  tow_to_rsi: true,
  impound_by: null,
  impound_by_department: null,
  dossier: null,
  impound_type: 1,
  reason: null,
});

const filteredStreets = computed(() => {
  if (loadingStreets.value) {
    return [{ value: 0, title: 'Loading...' }];
  }

  return streets;
});

const makes = ref([]);
const models = ref([]);
const loadingModels = ref(false);
const types = ref([]);
const colors = ref([]);
const departmentAuthorizers = ref([]);

const departments = computed(() => {
  return (
    gates.departments?.map((department) => ({
      value: department.id,
      title: `${department.company.handle_name} [${department.name}]`,
    })) || []
  );
});

watch(make, (value) => {
  if (!parseInt(value?.value || value)) {
    return;
  }

  fetchModels();
});

function fillForm() {
  if (impoundId && request) {
    form.address = request.address;
    form.house_number = request.house_number;

    form.dossier = request.dossier;
    form.impound_by = request.impound_by;
    form.impound_by_department = request.impound_by_department;
    form.impound_type = request.impound_type || 1;
    form.reason = request.reason;
    form.tow_to_rsi = request.tow_to_rsi || true;

    if (request.vehicle) {
      form.vehicle.plate_number = request.vehicle.plate_number;
      form.vehicle.model = request.vehicle.model;
      make.value = request.vehicle.make;
      form.vehicle.chassis_number = request.vehicle.chassis_number;
      form.vehicle.color = request.vehicle.color;
      form.vehicle.type = request.vehicle.type;
      form.vehicle.year = request.vehicle.year;
    }

    if (request.owner) {
      form.owner.name = request.owner.name;
      form.owner.contact_number = request.owner.contact_number;
    }

    form.owner_is_driver = request.owner_is_driver || false;

    if (request.driver && !form.owner_is_driver) {
      form.driver.name = request.driver.name;
      form.driver.contact_number = request.driver.contact_number;
    }

    if (request.address) {
      streetModel.value = { title: request.address };
      streetSearch.value = request.address;
    }
  }
}

watch(
  () => request,
  () => fillForm()
);

function submit() {
  clearErrors();
  submitting.value = true;
  form.address = streetModel.value?.title || streetSearch.value;
  form.vehicle.make = make.value?.title || make.value;

  let request = null;

  if (!impoundId) {
    request = requestImpound(form).then((impound) => {
      setTimeout(() => {
        submitting.value = true;
      }, 200);

      // redirect to impound page
      emit('created', impound);
      location.href = route('impounds.show', impound.id);
    });
  } else {
    request = updateRequest(impoundId, form).then(() => {
      emit('updated');
    });
  }

  request
    .catch((error) => {
      if (setErrorsFromResponse(error)) {
        return;
      }

      throw error;
    })
    .finally(() => {
      submitting.value = false;
    });
}

function fetchColors() {
  colors.value = [];

  getVehicleColors().then((c) => {
    colors.value = c.map((color) => ({ value: color.id, title: color.name }));
  });
}

function fetchVehicleTypes() {
  types.value = [];

  getVehicleTypes().then((t) => {
    types.value = t.map((type) => ({ value: type.id, title: type.name }));
  });
}

function fetchMakes() {
  makes.value = [];

  getVehicleMakes().then((m) => {
    makes.value = m.map((make) => ({ value: make.id, title: make.name }));
  });
}

function fetchModels() {
  form.vehicle.model = null;
  models.value = [];

  const makeId = make.value?.value || make.value;

  if (!parseInt(makeId)) {
    return;
  }

  loadingModels.value = true;

  getVehicleModels(makeId)
    .then((m) => {
      models.value = m.map((model) => ({ value: model.id, title: model.name }));
    })
    .finally(() => {
      loadingModels.value = false;
    });
}

function updateAuthorizers() {
  if (!form.department_id) {
    departmentAuthorizers.value = [];
    return;
  }

  getDepartmentAuthorizer(form.department_id).then((authorizers) => {
    departmentAuthorizers.value = authorizers.map((authorizer) => ({
      value: authorizer.id,
      title: authorizer.firstname + ' ' + authorizer.lastname,
    }));
  });
}

function updateDepartment() {
  if (departments.value.length === 1) {
    form.department_id = departments.value[0].value;
    updateAuthorizers();
  }
}

fetchMakes();
fetchVehicleTypes();
fetchColors();

fillForm();

setTimeout(() => {
  updateDepartment();
}, 100);
</script>
