<template>
  <v-footer class="bg-grey-darken-4 text-center" padless>
    <v-container>
      <v-row>
        <v-col cols="12" sm="4">
          <h3>Contact Us</h3>

          <v-list class="bg-grey-darken-4">
            <v-list-item prepend-icon="mdi-phone-classic">
              <a href="tel:+2976404545" class="text-reset"> 640-4545 </a>
            </v-list-item>

            <v-list-item prepend-icon="mdi-cellphone">
              <a href="tel:+2977314646" class="text-reset"> 731-4646 </a>
            </v-list-item>

            <v-list-item prepend-icon="mdi-email">
              <a href="mailto:collections@rsiaruba.com" class="text-reset">
                collections@rsiaruba.com
              </a>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" sm="4">
          <h3>Business Hours</h3>

          <v-list class="bg-grey-darken-4">
            <v-list-item prepend-icon="mdi-clock">
              <v-list-item-title>Monday to Saturday</v-list-item-title>
              <v-list-item-subtitle>8:00 AM - 10:00 PM</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" sm="4">
          <h3>Follow Us</h3>

          <v-list class="bg-grey-darken-4">
            <v-list-item prepend-icon="mdi-facebook">
              <a href="https://www.facebook.com/rsi.incasso" class="text-reset">
                Facebook
              </a>
            </v-list-item>

            <v-list-item prepend-icon="mdi-instagram">
              <a
                href="https://www.instagram.com/incassobureau_rsi"
                class="text-reset"
              >
                Instagram
              </a>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" class="text-center">
          &copy; {{ new Date().getFullYear() }} Recovery Solutions &
          Investigations (RSI)
          <br />
          All Rights Reserved
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
