<template>
  <v-responsive>
    <v-app :theme="theme" class="bg-red-gradient">
      <v-app-bar
        prominent
        class="d-print-none"
        :color="env !== 'production' ? 'red-accent-2' : null"
      >
        <v-app-bar-nav-icon variant="text" @click.stop="toggleDrawer" />

        <v-toolbar-title class="font-weight-bold">
          RSI {{ env !== 'production' ? ' - ' + env : '' }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="impersonated"
          href="?asUser=off"
          color="red"
          text="Impersonate off"
        />

        <NavAddMenu v-if="$gates.hasPermission('user.is_employee')" />

        <NavSearch class="mx-2" />

        <v-btn :icon="icon" @click="changeTheme" />

        <NavNotification />
      </v-app-bar>

      <AppNav
        v-model="drawer"
        :color="env !== 'production' ? 'red-accent-1' : null"
      />

      <v-main>
        <slot name="header" />

        <div class="m-2">
          <slot />
        </div>

        <AlertMessages />
      </v-main>
    </v-app>
  </v-responsive>
</template>

<script setup>
import AppNav from '@/components/layouts/AppNav.vue';
import NavAddMenu from '@/components/menus/NavAddMenu.vue';
import NavSearch from '@/components/menus/NavSearch.vue';
import NavNotification from '@/components/menus/NavNotification.vue';
import AlertMessages from '@/components/general/AlertMessages.vue';

import useThemeColor from '@/composables/theme/themeColor.js';
import useDrawerStatus from '@/composables/theme/drawerStatus.js';

import { inject } from 'vue';

const { impersonated, env } = inject('sharedData');
const { theme, icon, changeTheme } = useThemeColor();
const { drawer, toggleDrawer } = useDrawerStatus();
</script>
