import axios from '../../plugins/axios.js';

/**
 * @param {object} impound
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const createImpound = (impound, config = undefined) => {
  return axios
    .post('/api/v1/impounds', impound, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impound_id
 * @param {object} request
 * @param {object} config
 * @returns {Promise<object>}
 */
export const getImpound = (impound_id, request, config = undefined) => {
  return axios
    .get('/api/v1/impounds/' + impound_id, request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impound_id
 * @param {object} impound
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const updateImpound = (impound_id, impound, config = undefined) => {
  return axios
    .patch('/api/v1/impounds/' + impound_id, impound, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impoundId
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getRequestHistory = (impoundId, request, config = undefined) => {
  return axios
    .get(route('api.v1.impounds.request.history', [impoundId]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impoundId
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const updateRequest = (impoundId, request, config = undefined) => {
  return axios
    .patch(
      route('api.v1.impounds.request.update', [impoundId]),
      request,
      config
    )
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const requestImpound = (request, config = undefined) => {
  return axios
    .post(route('api.v1.impounds.request.store'), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impoundId
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const makeTransferVehicle = (impoundId, request, config = undefined) => {
  return axios
    .post(
      route('api.v1.impounds.actions.make-transfer', [impoundId]),
      request,
      config
    )
    .then((response) => response.data.data);
};

/**
 * @param {number} impoundId
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const sendToDestroyVehicle = (
  impoundId,
  request,
  config = undefined
) => {
  return axios
    .post(
      route('api.v1.impounds.actions.send-to-destroy', [impoundId]),
      request,
      config
    )
    .then((response) => response.data.data);
};

/**
 * @param {number} impound_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getSteps = (impound_id, config = undefined) => {
  return axios
    .get('/api/v1/impounds/' + impound_id + '/steps', config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request filters
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getImpounds = (request, config = undefined) => {
  return axios
    .get(route('api.v1.impounds.index'), { ...config, params: request })
    .then((response) => response.data);
};

/**
 * @param {object} request filters
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getImpoundCosts = (request, config = undefined) => {
  return axios
    .get(route('api.v1.impounds.lists.costs'), { ...config, params: request })
    .then((response) => response.data);
};
