<template>
  <v-row>
    <v-col cols="12" class="d-none d-print-block">
      <PageHeader />
    </v-col>

    <v-col cols="8" offset="2">
      <v-card class="d-none d-print-block text-center">
        <v-card-title class="h2">PROCÈS-VERBAL</v-card-title>
        <v-card-text class="h4 p-0">DELIVERY OF A WRITTEN NOTICE</v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="7" md="6">
      <ReprimandInfoCard :reprimand="reprimand" />
    </v-col>

    <v-col cols="12" sm="5" md="6">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="text-center">Invocation</v-card-title>

            <v-card-text>
              <template v-if="reprimand.reason">
                <div>{{ reprimand.reason.description }}</div>
              </template>

              <template v-if="reprimand.nota">
                <br />

                <div>
                  <span class="font-weight-bold">Note: </span>
                  {{ reprimand.nota }}
                </div>
              </template>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card v-if="reprimand.job_description">
            <v-card-title class="text-center">Description</v-card-title>

            <v-card-text>
              <div>{{ reprimand.job_description }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <TaskStepListCard
        section="reprimand"
        :section-id="reprimandId"
        @step-completed="fetchReprimand(reprimandId)"
        @rollback-step="fetchReprimand(reprimandId)"
      />
    </v-col>

    <v-col cols="12">
      <ReprimandFinancialCard :reprimand="reprimand" />
    </v-col>

    <v-col cols="12" md="6">
      <SectionInvoiceCard
        v-if="reprimand.id && !reprimand.debt"
        :section-id="reprimand.id"
        section="reprimand"
      />

      <v-card v-if="reprimand.debt">
        <v-card-title class="text-center"> Invoices </v-card-title>
        <v-card-text>
          All invoices have to be booked in the collection case.
          <br />
          Collection:
          <a
            :href="route('debts.actions.index', reprimand.debt.id)"
            class="font-weight-bold text-reset"
          >
            {{ reprimand.debt.ref_num }}
          </a>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <SectionNoteCard
        v-if="reprimand.id"
        v-model:items-counter="notesCount"
        :class="{ 'd-print-none': notesCount === 0 }"
        :section-id="reprimand.id"
        section="reprimand"
      />
    </v-col>

    <v-col cols="12" class="d-none d-print-block mt-4">
      <SignFooter
        v-if="reprimand.verifiedBy"
        :verified-by="reprimand.verifiedBy"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import SectionNoteCard from '@/components/card/general/SectionNoteCard.vue';
import TaskStepListCard from '@/components/card/general/TaskStepListCard.vue';
import SectionInvoiceCard from '@/components/card/invoice/SectionInvoiceCard.vue';
import ReprimandFinancialCard from '@/components/card/reprimand/ReprimandFinancialCard.vue';
import ReprimandInfoCard from '@/components/card/reprimand/ReprimandInfoCard.vue';
import PageHeader from '@/components/general/PageHeader.vue';
import SignFooter from '@/components/general/SignFooter.vue';

import { reprimand, fetchReprimand } from '@/store/reprimand/reprimand.js';
import { ref } from 'vue';

const notesCount = ref(0);

const { reprimandId } = defineProps({
  reprimandId: {
    type: Number,
    required: true,
  },
});

fetchReprimand(reprimandId);
</script>
