<template>
  <v-navigation-drawer expand-on-hover :rail="rail" class="d-print-none">
    <v-list>
      <v-list-item
        prepend-icon="mdi-account"
        :href="route('home')"
        :subtitle="user.email"
        :title="user.person.firstname + ' ' + user.person.lastname"
      />
    </v-list>

    <v-divider />

    <v-list nav density="compact">
      <template v-for="(option, index) in menuOptions" :key="index">
        <v-list-group
          v-if="option.type === 'group'"
          density="compact"
          :value="option.value"
          :color="option.color"
          :fluid="option.fluid"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              density="compact"
              :prepend-icon="option.icon"
              :title="option.title"
            />
          </template>

          <template v-for="(item, index2) in option.items" :key="index2">
            <v-list-item
              density="compact"
              :prepend-icon="item.icon"
              :title="item.title"
              :href="item.href"
            />
          </template>
        </v-list-group>
        <v-list-item
          v-else
          density="compact"
          :prepend-icon="option.icon"
          :title="option.title"
          :href="option.href"
        />
      </template>
    </v-list>

    <template #append>
      <v-list>
        <v-list-item
          :prepend-icon="
            rail ? 'mdi-arrow-collapse-right' : 'mdi-unfold-less-vertical'
          "
          :title="rail ? 'Disable rail' : 'Rail'"
          @click="rail = !rail"
        />

        <v-list-item
          prepend-icon="mdi-logout"
          title="Logout"
          :href="route('logout')"
        />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { computed, watch } from 'vue';
import { inject, ref } from 'vue';
const { user } = inject('sharedData');
const $gates = inject('$gates');
const rail = ref(localStorage.getItem('rail') === 'true');

const options = [
  {
    type: 'group',
    title: 'Registries',
    icon: 'mdi-folder-information',
    value: 'registries',
    items: [
      {
        title: 'RSI staff meetings',
        icon: 'mdi-forum',
        value: 'staff-meetings',
        href: '/meeting/all',
        can: 'user.is_employee|meeting.list',
      },
      {
        title: 'Natural persons',
        icon: 'mdi-card-account-details',
        value: 'natural-persons',
        href: route('people.index'),
        can: 'user.is_employee|person.list',
      },
      {
        title: 'Legal entities',
        icon: 'mdi-domain',
        value: 'legal-entities',
        href: '/companies',
        can: 'user.is_employee|company.list',
      },
      {
        title: 'Vehicles',
        icon: 'mdi-car-multiple',
        value: 'vehicles',
        href: route('vehicles.index'),
        can: 'user.is_employee|vehicle.list',
      },
      {
        title: 'Items',
        icon: 'mdi-train-car-container',
        value: 'items',
        href: '/items/all',
        can: 'user.is_employee|item.list',
      },
    ],
  },
  {
    type: 'group',
    title: 'Dashboard',
    icon: 'mdi-view-dashboard-edit',
    value: 'dashboard',
    items: [
      {
        title: 'Services',
        icon: 'mdi-table-filter',
        value: 'services',
        href: '/dashboard/services',
        can: 'user.is_employee',
      },
      {
        title: 'Users',
        icon: 'mdi-table-filter',
        value: 'users',
        href: '/users',
        can: 'user.is_employee|user.list',
      },
      {
        title: 'Roles',
        icon: 'mdi-table-filter',
        value: 'roles',
        href: '/roles',
        can: 'user.is_employee|role.list',
      },
      {
        title: 'Punch',
        icon: 'mdi-table-filter',
        value: 'punch',
        href: '/user/punch/??',
        can: 'user.is_employee|punch.list',
      },
      {
        title: 'Addresses',
        icon: 'mdi-table-filter',
        value: 'addresses',
        href: '/dashboard/addresses',
        can: 'user.is_employee',
      },
      {
        title: 'Reasons',
        icon: 'mdi-table-filter',
        value: 'reasons',
        href: '/dashboard/reasons',
        can: 'user.is_employee',
      },
    ],
  },
  {
    type: 'group',
    title: 'Collections',
    icon: 'mdi-cash-multiple',
    value: 'collections',
    items: [
      {
        title: 'Alle casussen',
        icon: 'mdi-table-filter',
        value: 'all-cases',
        href: route('debts.index'),
        can: 'debt.list',
        primary: true,
      },
      {
        title: 'Verzuimregistratie',
        icon: 'mdi-table-filter',
        value: 'overdue-debts',
        href: '/dashboard/overdue_debts',
        can: 'user.is_employee',
      },
      {
        title: 'Nog te verdelen',
        icon: 'mdi-table-filter',
        value: 'pending-distributions',
        href: '/dashboard/distributions',
        can: 'user.is_employee',
      },
      {
        title: 'Batenverdeling',
        icon: 'mdi-table-filter',
        value: 'distributions-history',
        href: route('debts.lists.distributions'),
        can: 'debt.list.distributions',
      },
      {
        title: 'Percentages',
        icon: 'mdi-table-filter',
        value: 'percentages',
        href: '/dashboard/percentages',
        can: 'user.is_employee',
      },
      {
        title: 'Betalingen',
        icon: 'mdi-table-filter',
        value: 'payments',
        href: '/dashboard/payments',
        can: 'user.is_employee',
      },
      {
        title: 'Notulen register',
        icon: 'mdi-table-filter',
        value: 'notes',
        href: '/dashboard/notes',
        can: 'user.is_employee',
      },
      {
        title: 'Ondernomen actie',
        icon: 'mdi-table-filter',
        value: 'actions',
        href: '/dashboard/actions',
        can: 'user.is_employee',
      },
      {
        title: 'Documentenbeheer',
        icon: 'mdi-table-filter',
        value: 'documents',
        href: '/dashboard/',
        can: 'user.is_employee',
      },
      {
        title: 'Overview',
        icon: 'mdi-table-filter',
        value: 'overview',
        href: '/debt/client',
        can: 'user.is_employee',
      },
      {
        title: 'Facturen',
        icon: 'mdi-table-filter',
        value: 'invoices',
        href: '/dashboard/summary_invoices',
        can: 'user.is_employee',
      },
    ],
  },
  {
    type: 'item',
    title: 'Written notices',
    icon: 'mdi-email-fast',
    value: 'reprimand',
    href: route('reprimands.index'),
    can: 'reprimand.list',
  },
  {
    type: 'item',
    title: 'Repossession',
    icon: 'mdi-car-key',
    value: 'repossession',
    href: route('repossessions.index'),
    can: 'repossession.list',
  },
  {
    type: 'item',
    title: 'Storage',
    icon: 'mdi-garage-variant',
    value: 'storage',
    href: route('storages.index'),
    can: 'storage.list',
  },
  {
    type: 'group',
    title: 'Impounding',
    icon: 'mdi-tow-truck',
    value: 'impound',
    href: route('impounds.index'),
    items: [
      {
        title: 'All cases',
        icon: 'mdi-table-filter',
        value: 'impound',
        href: route('impounds.index'),
        can: 'impound.list',
        primary: true,
      },
      {
        title: 'Costs',
        icon: 'mdi-table-filter',
        value: 'costs',
        href: route('impounds.lists.costs'),
        can: 'impound.list.costs',
      },
      {
        title: 'Request impound',
        icon: 'mdi-plus',
        value: 'impound-request',
        href: route('impounds.request'),
        can: 'impound.request.create',
      },
    ],
  },
  {
    type: 'item',
    title: 'Patrol',
    icon: 'mdi-shield-car',
    value: 'patrol',
    href: '/patrol/all',
    can: 'patrol.contract.list',
  },
  {
    type: 'item',
    title: 'Sales',
    icon: 'mdi-store',
    value: 'sales',
    href: '/sales',
    can: 'user.is_employee',
  },
  {
    type: 'item',
    title: 'Board Elections',
    icon: 'mdi-vote',
    value: 'vote',
    href: '/vote/events',
    can: 'user.is_employee',
  },
  {
    type: 'item',
    title: 'Invoices',
    icon: 'mdi-cash-register',
    value: 'invoice',
    href: '/invoice/all',
    can: 'invoice.list',
  },
  {
    type: 'group',
    title: 'Administration',
    icon: 'mdi-bookshelf',
    value: 'administration',
    items: [
      {
        title: 'Incassso/Regulieren',
        icon: 'mdi-table-filter',
        value: 'debts',
        href: '/accounting/debts',
        can: 'accounting.list.debts',
      },
      {
        title: 'Invoices',
        icon: 'mdi-table-filter',
        value: 'invoices',
        href: '/accounting/invoices',
        can: 'accounting.list.invoices',
      },
    ],
  },
];

const menuOptions = computed(() => {
  return options
    .map((option) => {
      option.can = option.can ? $gates.hasAllPermissions(option.can) : true;

      if (option.type === 'group') {
        option.items = option.items
          .map((item) => {
            item.can = item.can ? $gates.hasAllPermissions(item.can) : true;

            return item;
          })
          .filter((item) => item.can);

        if (option.items.length == 0) {
          option.can = false;
        } else if (
          option.items.length == 1 &&
          option.items[0].primary == true
        ) {
          option.type = 'item';
          option.can = option.items[0].can;
          option.href = option.items[0].href;
          option.items = undefined;
        }

        return option;
      }

      return option;
    })
    .filter((option) => option.can);
});

watch(rail, (value) => {
  localStorage.setItem('rail', value);
});
</script>
