import { getImpound } from '@/services/api/impound.js';
import { reactive, ref } from 'vue';

const impound = reactive({});
const loading = ref(false);

const fetchImpound = (impoundId) => {
  loading.value = true;
  return getImpound(impoundId)
    .then((i) => {
      Object.assign(impound, i);

      return i;
    })
    .finally(() => {
      loading.value = false;
    });
};

export { impound, fetchImpound, loading };
